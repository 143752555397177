export function initNavbar() {
    // Selektiere alle Level-2-Einträge, die Untermenüs enthalten
    const dropdownItems = document.querySelectorAll('.dropdown-submenu > span');

    dropdownItems.forEach(item => {
        item.addEventListener('click', function (event) {
            // Verhindere den Standard-Link-Klick
            event.preventDefault();
            event.stopPropagation(); // Verhindert, dass das Schließen des Elternmenüs ausgelöst wird

            // Toggle die Sichtbarkeit des Submenüs
            const submenu = item.nextElementSibling;
            if (submenu) {
                const isVisible = submenu.classList.contains('show');

                // Nur schließen, wenn das Submenü bereits sichtbar ist
                if (!isVisible) {
                    submenu.classList.add('show');
                } else {
                    submenu.classList.remove('show');
                }
            }
        });
    });

    // Klicke außerhalb, um alle offenen Submenüs zu schließen
    document.addEventListener('click', function (event) {
        if (!event.target.closest('.dropdown-submenu')) {
            document.querySelectorAll('.dropdown-submenu .dropdown-menu').forEach(menu => {
                menu.classList.remove('show');
            });
        }
    });
}
