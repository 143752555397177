import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import deLocale from '@fullcalendar/core/locales/de';

import bootstrap5Plugin from '@fullcalendar/bootstrap5';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

export function initCalendar() {
    const calendarEl = document.getElementById('calendar');
    if (!calendarEl) {
        return;
    }

    const calendar = new Calendar(calendarEl, {
        plugins: [dayGridPlugin, bootstrap5Plugin],  // Plugins hinzufügen
        themeSystem: 'bootstrap5',  // Bootstrap-Theme verwenden
        headerToolbar: {
            left: 'prev',
            center: 'title',
            right: 'next'
        },
        initialView: 'dayGridMonth',
        locale: deLocale,  // Deutsche Lokalisierung
        eventDisplay: 'block',  // Events als Block anzeigen
        height: 'auto',  // Automatische Höhe

        eventDidMount: function (info) {
            let tooltipTitle = `<i class="bi-calendar3 me-2"></i><strong>${info.event.title}</strong>`;  // Eventtitel in Fettdruck

            // Entscheidung, ob es sich um ein ganztägiges Event handelt
            if (info.event.allDay) {
                tooltipTitle += "<br><div class='small text-center text-light'>Ganztägig</div>";  // Für ganztägige Events
            } else {
                // Prüfung, ob das Event innerhalb eines Tages startet und endet
                if (info.event.start.toDateString() === info.event.end.toDateString()) {
                    // Eintägiges Event mit Zeiten
                    tooltipTitle += `<br><i class="bi-clock me-2"></i>${info.event.start.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })}`;
                    if (info.event.end) {
                        tooltipTitle += ` - ${info.event.end.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })} Uhr`;
                    } else {
                        tooltipTitle += ')';  // Wenn kein Endzeitpunkt definiert ist
                    }
                } else {
                    // Mehrtägiges Event mit Datum und Uhrzeit, verwende originalEnd, falls vorhanden
                    const endDate = info.event.extendedProps.originalEnd || info.event.end;
                    tooltipTitle += `<br>${info.event.start.toLocaleDateString('de-DE', { year: '2-digit', month: '2-digit', day: '2-digit' })}, ` +
                        `${info.event.start.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })} Uhr - ` +
                        `${new Date(endDate).toLocaleDateString('de-DE', { year: '2-digit', month: '2-digit', day: '2-digit' })}, ` +
                        `${new Date(endDate).toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })} Uhr`;
                }
            }

            // Überprüfen, ob das aktuelle Event mehrtägig ist
            const eventStart = info.event.start;
            const eventEnd = info.event.end || eventStart;
            const isMultiDay = eventEnd.getDate() > eventStart.getDate() ||
                eventEnd.getMonth() > eventStart.getMonth() ||
                eventEnd.getFullYear() > eventStart.getFullYear();

            if (isMultiDay) {
                info.el.classList.add('fc-event-multiday');
            } else {
                // Zähle die Anzahl der mehrtägigen Events, die den Tag des aktuellen eintägigen Events umfassen
                let multidayCount = 0;
                calendar.getEvents().forEach(event => {
                    const otherStart = event.start;
                    const otherEnd = event.end || otherStart;
                    const isOtherMultiDay = otherEnd.getDate() > otherStart.getDate() ||
                        otherEnd.getMonth() > otherStart.getMonth() ||
                        otherEnd.getFullYear() > otherStart.getFullYear();

                    // Prüfe, ob das andere Event mehrtägig ist und den aktuellen Tag des Events umfasst
                    if (isOtherMultiDay &&
                        eventStart >= otherStart &&
                        eventStart < otherEnd) { // Achte darauf, dass das Startdatum des aktuellen eintägigen Events im mehrtägigen Event enthalten ist
                        multidayCount++;
                    }
                });

                // Füge die entsprechende Klasse hinzu, wenn es mehrtägige Events gibt, die über diesem eintägigen Event liegen
                if (multidayCount > 0) {
                    info.el.classList.add(`fc-multiday-above-${multidayCount}`);
                }
            }

            // Erstelle den Tooltip mit HTML-Unterstützung
            const tooltip = new bootstrap.Tooltip(info.el, {
                title: tooltipTitle,
                placement: 'top',
                trigger: 'hover',
                container: 'body',
                html: true,
                customClass: 'calendar-tooltip'
            });
        },

        events: function (fetchInfo, successCallback, failureCallback) {
            $.ajax({
                url: '/api/calendar/1/events/',
                method: 'GET',
                success: function (response) {
                    const events = response.events.map(event => {
                        // Prüfe, ob das Event ein ganztägiges Event ohne Zeitangaben ist
                        const isAllDay = event.allDay || (!event.start.includes('T') && !event.end?.includes('T'));

                        // Erstelle ein neues Event-Objekt
                        const eventObject = {
                            title: event.title,
                            start: event.start,
                            end: event.end,
                            color: event.color,
                            url: event.url,
                            allDay: isAllDay,
                        };

                        // Prüfe, ob das Event ganztägig ist und ob Start- und Enddatum unterschiedlich sind
                        if (isAllDay && event.end && event.start !== event.end) {
                            const startDate = new Date(event.start);
                            const endDate = new Date(event.end);

                            // Wenn das Event mehr als einen Tag dauert, erhöhen wir das Enddatum um einen Tag
                            if (endDate > startDate) {
                                endDate.setDate(endDate.getDate() + 1);
                                eventObject.end = endDate.toISOString().split('T')[0]; // Enddatum als 'YYYY-MM-DD'
                                eventObject.originalEnd = event.end; // Speichere das ursprüngliche Enddatum
                            }
                        }

                        return eventObject;
                    });

                    successCallback(events);
                },
                error: function (xhr, status, error) {
                    console.log("Error fetching events: " + error);
                }
            });
        },
    });

    calendar.render();
}
