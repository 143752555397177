export function adjustGalleries(gallerySelector = '.news-gallery', colsSelector = '.col') {
    const galleries = document.querySelectorAll(gallerySelector);
    const indicatorCols = document.querySelectorAll('.indicator-col button');

    galleries.forEach(gallery => {
        const cols = gallery.querySelectorAll(colsSelector);
        const galleryWidth = gallery.offsetWidth;

        let numberOfImages = 4; // Standard: 4 Bilder
        let margin = 6; // Standard: 6px Margin

        if (window.innerWidth >= 1200) {
            numberOfImages = 5; // Ab 1200px: 5 Bilder
        } else if (window.innerWidth < 426) {
            numberOfImages = 3; // Für kleine Bildschirme 3 Bilder
        }

        const totalMargin = (numberOfImages - 1) * margin;
        const availableWidth = galleryWidth - totalMargin;
        const colWidth = availableWidth / numberOfImages;

        cols.forEach(col => {
            col.style.flex = `0 0 ${colWidth}px`;
            col.style.height = `${colWidth}px`;
        });

        indicatorCols.forEach(indicator => {
            indicator.style.height = `${colWidth}px`;
        });
    });
}

// Funktion, um die JS-Anpassungen an der Galerie zurückzusetzen
export function resetGalleryStyles(gallerySelector, colsSelector) {
    const galleries = document.querySelectorAll(gallerySelector);
    galleries.forEach(gallery => {
        const cols = gallery.querySelectorAll(colsSelector);
        cols.forEach(col => {
            col.style.flex = ''; // Entferne die JS-Anpassungen
            col.style.height = ''; // Entferne die JS-Höhenanpassung
        });
    });
}

export function initGalleryIndicators() {
    const galleries = document.querySelectorAll('.gallery');

    galleries.forEach(function(gallery) {
        const itemId = gallery.getAttribute('data-post-id') || gallery.getAttribute('data-event-id');
        const indicator = document.getElementById('indicator-' + itemId);

        if (!indicator) {
            console.warn(`Indicator for itemId ${itemId} not found.`);
            return;
        }

        const arrowIcon = indicator.querySelector('i');
        if (!arrowIcon) {
            console.warn(`Arrow icon for indicator ${itemId} not found.`);
            return;
        }

        function getImageHeightWithMargins() {
            const image = gallery.querySelector('.gallery-image-col');
            if (!image) return 0;

            const style = window.getComputedStyle(image);
            const height = image.offsetHeight;
            const marginTop = parseFloat(style.marginTop);
            const marginBottom = parseFloat(style.marginBottom);

            return height + marginTop + marginBottom;
        }

        function isAtEndOrStart() {
            const scrollTop = gallery.scrollTop;
            const scrollHeight = gallery.scrollHeight;
            const clientHeight = gallery.clientHeight;
            const tolerance = 5;

            if (scrollTop + clientHeight >= scrollHeight - tolerance) {
                return 'end';
            } else if (scrollTop <= tolerance) {
                return 'start';
            } else {
                return 'middle';
            }
        }

        function updateIndicator() {
            const position = isAtEndOrStart();

            if (gallery.scrollHeight > gallery.clientHeight) {
                indicator.classList.add('show');
                if (position === 'end') {
                    arrowIcon.classList.remove('bi-chevron-down');
                    arrowIcon.classList.add('bi-chevron-up');
                } else if (position === 'start') {
                    arrowIcon.classList.remove('bi-chevron-up');
                    arrowIcon.classList.add('bi-chevron-down');
                }
            } else {
                indicator.classList.remove('show');
            }
        }

        function scrollGallery(direction) {
            const imageHeight = getImageHeightWithMargins();
            if (direction === 'down') {
                gallery.scrollBy({ top: imageHeight, behavior: 'smooth' });
            } else {
                gallery.scrollBy({ top: -imageHeight, behavior: 'smooth' });
            }
        }

        indicator.addEventListener('click', function() {
            const position = isAtEndOrStart();
            if (position === 'end' || arrowIcon.classList.contains('bi-chevron-up')) {
                scrollGallery('up');
            } else {
                scrollGallery('down');
            }
        });

        window.addEventListener('resize', updateIndicator);
        gallery.addEventListener('scroll', updateIndicator);
        updateIndicator();
    });
}
