export function initAnimations() {
    // Überprüfe die Bildschirmbreite
    if (window.innerWidth <= 992) {
        return; // Animationen nicht aktivieren, wenn das Fenster kleiner als 992px ist
    }

    // Wähle alle Elemente aus, die animiert werden sollen
    const elementsToAnimate = document.querySelectorAll('[class*="animation-"]');

    // Setze alle Elemente auf opacity 0 im JavaScript, damit sie ohne JS sichtbar bleiben
    elementsToAnimate.forEach(element => {
        element.style.opacity = 0;
        element.style.visibility = 'hidden';
    });

    // Erstelle den Intersection Observer
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            // Wenn das Element im Viewport sichtbar ist
            if (entry.isIntersecting) {
                // Finde die Klasse, die mit "animation-" beginnt
                const animationClass = Array.from(entry.target.classList).find(cls => cls.startsWith('animation-'));

                if (animationClass) {
                    // Extrahiere den tatsächlichen Animationsteil, z.B. "fadeInLeft" aus "animation-fadeInLeft"
                    const animationName = animationClass.replace('animation-', 'animate__');

                    // Füge die animate.css-Klassen hinzu
                    entry.target.classList.add('animate__animated', animationName);
                }

                // Mache das Element sichtbar, indem Du opacity und visibility setzt
                entry.target.style.opacity = 1;
                entry.target.style.visibility = 'visible';

                // Stoppe die Überwachung des Elements nach der Animation
                observer.unobserve(entry.target);
            }
        });
    }, {
        threshold: 0.4 // Das Element wird animiert, wenn 40% sichtbar sind
    });

    // Überprüfe jedes Element
    elementsToAnimate.forEach(element => {
        // Prüfe, ob das Element bereits im Viewport sichtbar ist
        const rect = element.getBoundingClientRect();
        const inViewport = (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );

        if (inViewport) {
            // Element ist bereits sichtbar, setze opacity und visibility auf sichtbar
            element.style.opacity = 1;
            element.style.visibility = 'visible';
        } else if (element.classList.contains('animate-scroll')) {
            // Wenn das Element nicht sichtbar ist und die Klasse "animate-scroll" hat, überwache es
            observer.observe(element);
        } else {
            // Wenn die Animation nicht durch Scrollen gestartet wird, direkt hinzufügen
            const animationClass = Array.from(element.classList).find(cls => cls.startsWith('animation-'));

            if (animationClass) {
                const animationName = animationClass.replace('animation-', 'animate__');
                element.classList.add('animate__animated', animationName);
                // Setze opacity und visibility, damit es sichtbar wird
                element.style.opacity = 1;
                element.style.visibility = 'visible';
            }
        }
    });
}
