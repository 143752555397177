function debounce(func, wait, immediate) {
    let timeout;
    return function () {
        const context = this, args = arguments;
        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (immediate && !timeout) func.apply(context, args);
    };
}

export function getItemWidth(scrollContainerSelector) {
    let col;

    // Unterscheide zwischen verschiedenen Galerietypen
    if (scrollContainerSelector === '.news-row') {
        col = document.querySelector('.news-card-column');
    } else if (scrollContainerSelector === '.news-gallery') {
        col = document.querySelector('.news-gallery .col');
    } else if (scrollContainerSelector === '.gallery') {
        col = document.querySelector('.gallery .gallery-image-col'); // Selektor für .gallery
    } else {
        throw new Error('Invalid scrollContainerSelector');
    }

    if (!col) {
        throw new Error(`Kein Element mit dem Selektor ${scrollContainerSelector} gefunden.`);
    }

    // Breite und Margin des Items berechnen
    const width = col.getBoundingClientRect().width;
    const computedStyle = window.getComputedStyle(col);
    const margin = 2 * parseFloat(computedStyle.marginRight); // Beide Seiten addieren
    return width + margin;
}

function checkScrollPosition(container) {
    const isAtStart = container.scrollLeft === 0;
    const isAtEnd = container.scrollLeft + container.clientWidth + 1 >= container.scrollWidth;
    return {isAtStart, isAtEnd};
}

function updateScrollButtons(container, leftButtonSelector, rightButtonSelector) {
    const {isAtStart, isAtEnd} = checkScrollPosition(container);
    document.querySelector(leftButtonSelector).style.visibility = isAtStart ? 'hidden' : 'visible';
    document.querySelector(rightButtonSelector).style.visibility = isAtEnd ? 'hidden' : 'visible';
}

function initScrollFunctionality(scrollContainerSelector, leftButtonSelector, rightButtonSelector) {
    const scrollContainer = document.querySelector(scrollContainerSelector);
    const leftButton = document.querySelector(leftButtonSelector);
    const rightButton = document.querySelector(rightButtonSelector);

    const update = function () {
        try {
            const itemWidth = getItemWidth(scrollContainerSelector); // Flexibler für alle Selektoren
            if (scrollContainer) {
                leftButton.onclick = () => {
                    scrollContainer.scrollBy({left: -itemWidth, behavior: 'smooth'});
                    setTimeout(() => updateScrollButtons(scrollContainer, leftButtonSelector, rightButtonSelector), 350);
                };
                rightButton.onclick = () => {
                    scrollContainer.scrollBy({left: itemWidth, behavior: 'smooth'});
                    setTimeout(() => updateScrollButtons(scrollContainer, leftButtonSelector, rightButtonSelector), 350);
                };
                updateScrollButtons(scrollContainer, leftButtonSelector, rightButtonSelector);
            }
        } catch (error) {
            console.error("Fehler beim Scroll-Update: ", error.message);
        }
    };

    update(); // Initialsetup
    scrollContainer.addEventListener('scroll', () => updateScrollButtons(scrollContainer, leftButtonSelector, rightButtonSelector));
    window.addEventListener('resize', debounce(update, 250)); // Resize-Listener mit Debouncing
}

export {initScrollFunctionality};
