export function initStickyBreadcrumbs() {
    // Elemente selektieren
    const breadcrumbContainer = document.querySelector('.bc-container');
    const mainContent = document.querySelector('.main-content');

    if (breadcrumbContainer && mainContent) {
        // Funktion zur Überprüfung der Sichtbarkeitsbedingungen
        function checkVisibility() {
            const scrollPosition = window.scrollY;
            const headingOffset = mainContent.getBoundingClientRect().top;
            let topOffset = 100;

            if (document.querySelector('.cms-toolbar-expanded') !== null) {
                topOffset = 146;
            }

            // Prüfen, ob der main-content unter die Navbar scrollt
            if (headingOffset <= topOffset) {
                breadcrumbContainer.style.opacity = '0';

                // Nur einmal den 'transitionend' Event hinzufügen, wenn die Opacity auf 0 geht
                breadcrumbContainer.addEventListener('transitionend', function onTransitionEnd(event) {
                    if (event.propertyName === 'opacity' && breadcrumbContainer.style.opacity === '0') {
                        breadcrumbContainer.style.visibility = 'hidden';
                        breadcrumbContainer.removeEventListener('transitionend', onTransitionEnd);
                    }
                });
            } else {
                // Sichtbarkeit setzen und Opacity auf 1
                breadcrumbContainer.style.visibility = 'visible';
                breadcrumbContainer.style.opacity = '1';
            }
        }

        // Eventlistener für das Scrollen hinzufügen
        window.addEventListener('scroll', checkVisibility);

        // Initiale Überprüfung ausführen, um die Sichtbarkeit sofort anzupassen
        checkVisibility();
    }
}
