import L from 'leaflet';

export function initMap() {

    // Initialize map with a default center and zoom level
    const map = L.map('map', {
        center: [51.4363, 14.252765], // Default to school location
        zoom: 13, // Set an initial zoom level
        zoomControl: false,
        attributionControl: false,
    });

    // Add the tile layer
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(map);

    L.control.attribution({
        position: 'bottomright', // Du kannst die Position anpassen
        prefix: false // Entfernt das "Leaflet" Branding
    }).addAttribution('&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>').addTo(map);


    // Define the school icon
    const schoolIcon = L.icon({
        iconUrl: '/static/img/map_icons/map_pin.png',
        shadowUrl: '/static/img/map_icons/map_pin_shadow.png',
        iconSize: [20, 50],
        shadowSize: [50, 50],
        iconAnchor: [10, 50],
        shadowAnchor: [15, 52],
        popupAnchor: [0, -50]
    });

    // School coordinates
    const schoolLatLng = L.latLng(51.4363, 14.252765);

    // Add school marker
    const schoolMarker = L.marker(schoolLatLng, {
        icon: schoolIcon,
        riseOnHover: true,
        title: 'Léon-Foucault-Gymnasium Hoyerswerda',
        alt: 'Léon-Foucault-Gymnasium Hoyerswerda',
        riseOffset: 250,
    }).addTo(map);

    // Bind popup to the school marker
    schoolMarker.bindPopup("<b>Léon-Foucault-Gymnasium Hoyerswerda</b>");

    // "Fly to" the school location with a zoom level of 16 after the map has been initialized
    map.flyTo(schoolLatLng, 16, {
        animate: true,
        duration: 2,  // 2 seconds for the fly animation
    });

    // Custom reset control
    const customControl = L.Control.extend({
        options: { position: 'topright' },
        onAdd: function () {
            const container = L.DomUtil.create('div', '');
            const button = L.DomUtil.create('button', 'btn btn-sm btn-light', container);
            button.innerHTML = '<i class="bi-arrow-clockwise"></i>';
            button.style = 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.5); border-radius: 5px;';

            L.DomEvent.disableClickPropagation(button);
            L.DomEvent.on(button, 'click', function () {
                map.flyTo(schoolLatLng, 16, { animate: true, duration: 1.5 });
            });

            return container;
        }
    });

    // Custom zoom control
    const customZoomControl = L.Control.extend({
        options: { position: 'topleft' },
        onAdd: function () {
            const controlContainer = L.DomUtil.create('div');

            // Zoom-In Button
            const zoomInButton = L.DomUtil.create('button', 'btn btn-sm btn-light d-block mb-1', controlContainer);
            zoomInButton.innerHTML = '<i class="bi bi-plus-lg"></i>';
            zoomInButton.style = 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.5); border-radius: 5px;';
            zoomInButton.onclick = function () {
                map.zoomIn();
            };

            // Zoom-Out Button
            const zoomOutButton = L.DomUtil.create('button', 'btn btn-sm btn-light d-block', controlContainer);
            zoomOutButton.innerHTML = '<i class="bi bi-dash-lg"></i>';
            zoomOutButton.style = 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.5); border-radius: 5px;';
            zoomOutButton.onclick = function () {
                map.zoomOut();
            };

            L.DomEvent.disableClickPropagation(controlContainer);
            return controlContainer;
        }
    });

    // Add custom controls to the map
    map.addControl(new customControl());
    map.addControl(new customZoomControl());

}
