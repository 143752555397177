import {initCalendar} from "./calendar";
import {initNavbar} from "./navbar";
import {togglePostText, toggleEventText, truncateText} from './textUtils';
import 'fslightbox';
import {initScrollFunctionality} from "./scrollUtils";
import {adjustGalleries, initGalleryIndicators, resetGalleryStyles} from "./galleryUtils";
import {initAnimations} from "./animations";
import {initCounter} from "./purecounter";
import {initStickyBreadcrumbs} from "./utils";
import {initMap} from "./map";


document.addEventListener("DOMContentLoaded", function () {
    initNavbar();
    initCalendar();
    initAnimations();
    initStickyBreadcrumbs();

    // Initialisierung der Karte, wenn ein entsprechendes Element auf der Seite vorhanden ist
    const mapContainers = document.querySelectorAll('#map');
    if (mapContainers.length > 0) {
            initMap();
        }

    // Initialisierung des PureCounter, wenn entsprechende Elemente auf der Seite vorhanden sind
    const counters = document.querySelectorAll('.purecounter');
    if (counters.length > 0) {
        initCounter();
    }

    // Überprüfe, ob es Galerien auf der Seite gibt (für beide Typen)
    const galleries = document.querySelectorAll('.gallery');
    const newsGalleries = document.querySelectorAll('.news-gallery');

    // Statusvariable, um zu wissen, ob die Galerie für mobile Geräte angepasst wurde
    let isGalleryAdjusted = false;

    // Funktion zur Handhabung des Resizes
    function handleResize() {
        const windowWidth = window.innerWidth;

        if (galleries.length > 0) {

            if (windowWidth < 992 && !isGalleryAdjusted) {
                adjustGalleries('.gallery', '.gallery-image-col'); // Anpassung für ".gallery" Galerien
                isGalleryAdjusted = true;
            } else if (windowWidth >= 992 && isGalleryAdjusted) {
                resetGalleryStyles('.gallery', '.gallery-image-col'); // Stile zurücksetzen
                isGalleryAdjusted = false;
            }
        }

        if (newsGalleries.length > 0) {
            adjustGalleries('.news-gallery', '.col'); // Anpassung für ".news-gallery" Galerien
        }
    }

    // Initialisiere die Galerien beim ersten Laden der Seite
    if (galleries.length > 0) {
        initGalleryIndicators(); // Galerien sind vorhanden, Indicators initialisieren
        handleResize(); // Prüfe beim Laden der Seite
        initScrollFunctionality('.gallery', '.scroll-indicator-left', '.scroll-indicator-right');
        window.addEventListener('resize', handleResize); // Prüfe auf Resize
    }

    if (newsGalleries.length > 0) {
        adjustGalleries('.news-gallery', '.col'); // Anpassung für ".news-gallery" Galerien
        window.addEventListener('resize', handleResize); // Sicherstellen, dass es auf Resize reagiert
        initScrollFunctionality(".news-gallery", ".scroll-indicator-left", ".scroll-indicator-right");
    }

    // Überprüfe, ob es eine NewsRow auf der Seite gibt
    const newsRow = document.querySelectorAll('.news-row');
    if (newsRow.length > 0) {
        initScrollFunctionality(".news-row", ".scroll-indicator-left", ".scroll-indicator-right");

        // Truncate-Funktion für Textcontainer
        for (let textContainer of document.querySelectorAll('.truncate')) {
            let textContainerParent = textContainer.parentElement;
            let maxHeight = textContainerParent.clientHeight - 40;
            truncateText(textContainer, maxHeight);
        }
    }
});

// initAnimations ausführen, wenn sich die Größe des Fensters ändert
window.addEventListener('resize', initAnimations);

// Bindung von togglePostText und toggleEventText
window.togglePostText = togglePostText;
window.toggleEventText = toggleEventText;

// Bindung von Navbar-Funktionen
//window.handleMobileSubmenuClick = handleMobileSubmenuClick;
//window.handleSubmenuHover = handleSubmenuHover;
//window.restoreHoverAfterOffcanvasClose = restoreHoverAfterOffcanvasClose;
