// Funktion zum Umschalten des Textes für Posts
export function togglePostText(postId) {
    const shortText = document.getElementById('short-text-' + postId);
    const fullText = document.getElementById('full-text-' + postId);
    const button = document.querySelector(`#preview-${postId} button`);

    if (fullText.style.display === 'none') {
        fullText.style.display = 'block';
        shortText.style.display = 'none';
        button.textContent = 'Weniger lesen';
    } else {
        fullText.style.display = 'none';
        shortText.style.display = 'block';
        button.textContent = 'Mehr lesen';
    }
}

// Funktion zum Umschalten des Textes für Events
export function toggleEventText(eventId) {
    const shortText = document.getElementById('short-text-' + eventId);
    const fullText = document.getElementById('full-text-' + eventId);
    const button = document.querySelector(`#preview-${eventId} button`);

    if (fullText.style.display === 'none') {
        fullText.style.display = 'block';
        shortText.style.display = 'none';
        button.textContent = 'Weniger lesen';
    } else {
        fullText.style.display = 'none';
        shortText.style.display = 'block';
        button.textContent = 'Mehr lesen';
    }
}

// Funktion zum Abschneiden von Texten basierend auf der Höhe des Textcontainers
export function truncateText(textContainer, maxHeight) {
    // Ersetze alle Zeilenumbrüche durch Leerzeichen und entferne zusätzliche Leerzeichen
    textContainer.textContent = textContainer.textContent.replace(/\s+/g, ' ').trim();

    // Verkürze den Text, wenn er die maximale Höhe überschreitet
    while (textContainer.scrollHeight > maxHeight && textContainer.textContent.length > 0) {
        // Reduziere schrittweise den Text
        textContainer.textContent = textContainer.textContent.replace(/\s*\S+\s*$/, '').trim() + '...';

        // Überprüfe, ob eine weitere Reduzierung sinnvoll ist, um Endlosschleifen zu vermeiden
        if (textContainer.scrollHeight <= maxHeight) {
            break;
        }
    }
}
